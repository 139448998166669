import React, { useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const SkillsSection = () => {
    const skillsRef = useRef(null);
    const isInView = useInView(skillsRef, { once: true, amount: 0.3 });

    const fadeInVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    const progressVariants = {
        hidden: { width: 0 },
        visible: (custom) => ({
            width: `${custom}%`,
            transition: {
                duration: 1.5,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1]
            }
        })
    };

    const skillItemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: (custom) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: custom * 0.1,
                duration: 0.5
            }
        })
    };

    return (
        <section id="skills" className="skills section-bg" ref={skillsRef}>
            <div className="container">
                <motion.div
                    className="section-title"
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    variants={fadeInVariants}
                >
                    <h2>Skills</h2>
                    <p>
                        Here are some of the skills that I have acquired and mastered over the years, along with the
                        percentage of my proficiency in each one:
                    </p>
                </motion.div>
                <div className="row skills-content">
                    <div className="col-lg-6">
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={0}
                        >
                            <span className="skill">React JS <i className="val">98%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={98}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={1}
                        >
                            <span className="skill">NEXT JS <i className="val">80%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={80}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={2}
                        >
                            <span className="skill">JavaScript <i className="val">98%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={98}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                    </div>

                    <div className="col-lg-6">
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={3}
                        >
                            <span className="skill">HTML5 / CSS3 <i className="val">95%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={95}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={4}
                        >
                            <span className="skill">TAILWIND CSS <i className="val">85%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={85}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                        <motion.div
                            className="progress"
                            initial="hidden"
                            animate={isInView ? "visible" : "hidden"}
                            variants={skillItemVariants}
                            custom={5}
                        >
                            <span className="skill">NODE <i className="val">70%</i></span>
                            <div className="progress-bar-wrap">
                                <motion.div
                                    className="progress-bar"
                                    role="progressbar"
                                    variants={progressVariants}
                                    custom={70}
                                    initial="hidden"
                                    animate={isInView ? "visible" : "hidden"}
                                ></motion.div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SkillsSection;