import React from 'react';
import { motion } from 'framer-motion';

const birthYear = 2000;
function calculateAge() {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
}

const AboutSection = () => {
    const fadeInVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    const staggerContainerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3
            }
        }
    };

    const listItemVariants = {
        hidden: { opacity: 0, x: -10 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    return (
        <section id="about_section" className="about_section">
            <div className="container">
                <motion.div
                    className="section-title"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                    variants={fadeInVariants}
                >
                    <h2>About</h2>
                    <p>
                        My name is Shailesh Patil and I have a bachelor's degree in computer applications. I started my
                        career as a programmer after completing my BCA. I have always been passionate about creating and
                        solving problems using technology. I enjoy learning new skills and tools to improve my web
                        development and design abilities.
                    </p>
                </motion.div>

                <div className="row">
                    <motion.div
                        className="col-lg-4"
                        initial={{ opacity: 0, scale: 0.9 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true, amount: 0.3 }}
                        transition={{ duration: 0.5 }}
                    >
                        <motion.img
                            src="/assets/profile_passport.jpeg"
                            className="img-fluid"
                            alt="image"
                            style={{
                                height: 368,
                                width: "100%",
                                objectFit: "contain",
                                margin: "auto"
                            }}
                            whileHover={{
                                scale: 1.03,
                                boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)"
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </motion.div>
                    <motion.div
                        className="col-lg-8 pt-4 pt-lg-0 content"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={fadeInVariants}
                    >
                        <motion.h3 variants={fadeInVariants}>Web Developer & Designer.</motion.h3>
                        <motion.p className="fst-italic" variants={fadeInVariants}>
                            My expertise lies in harnessing the power of
                            modern technologies to create seamless and engaging digital experiences.
                        </motion.p>
                        <div className="row">
                            <div className="col-lg-6">
                                <motion.ul
                                    variants={staggerContainerVariants}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.3 }}
                                >
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Full Name:</strong>
                                        <span>Shailesh Patil</span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Birthday:</strong>
                                        <span>22 July {birthYear}</span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Phone:</strong>
                                        <span>+91 88669 65956</span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>City:</strong>
                                        <span>Surat, INDIA</span>
                                    </motion.li>
                                </motion.ul>
                            </div>
                            <div className="col-lg-6">
                                <motion.ul
                                    variants={staggerContainerVariants}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.3 }}
                                >
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Age:</strong>
                                        <span>{calculateAge()}</span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Degree:</strong>
                                        <span>Master of Computer Applications</span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Email:</strong>
                                        <span>
                                            <a href="mailto:shaileshdpatil1@gmail.com">
                                                shaileshdpatil1@gmail.com
                                            </a>
                                        </span>
                                    </motion.li>
                                    <motion.li variants={listItemVariants}>
                                        <i className="bi bi-chevron-right" /> <strong>Freelance:</strong>
                                        <span>Available</span>
                                    </motion.li>
                                </motion.ul>
                            </div>
                        </div>
                        <motion.p
                            variants={fadeInVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.3 }}
                        >
                            In my spare time, I also run a YouTube channel under my name, where I share my knowledge and
                            experience in web development and design with other aspiring programmers. I hope you enjoy
                            browsing my portfolio and feel free to contact me if you have any questions or inquiries.
                        </motion.p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
