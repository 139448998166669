import { useEffect } from "react";
import "./App.css";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { motion } from "framer-motion";
import HeaderContainer from "./components/HeaderContainer";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import FactsSection from "./components/FactsSection";
import SkillsSection from "./components/SkillsSection";
import ResumeSection from "./components/ResumeSection";
import TestimonialsSection from "./components/TestimonialsSection";
import ScrollToTop from "./components/ScrollToTop";
import ContactForm from "./components/ContactForm";

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <HeaderContainer />
            <HeroSection />
            <main id="main">
                <AboutSection />
                <FactsSection />
                <SkillsSection />
                <ResumeSection />
                <TestimonialsSection />
                <ContactForm />
            </main>
            <ScrollToTop />
        </motion.div>
    );
}

export default App;
