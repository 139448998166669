import React from 'react';
import TypedReactDemo from "../TypedReactDemo";
import { motion } from "framer-motion";

const HeroSection = () => {
    const handleSidebar = () => {
        document.querySelector("header").classList.add("left_active");
    }

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
                delayChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    return (
        <section id="hero_content" className="d-flex flex-column justify-content-center align-items-center">
            <motion.img
                src="/assets/lines.png" alt="lines" className="nav-menu_icon"
                onClick={handleSidebar}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            />
            <motion.div
                className="hero-container"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.h1 variants={itemVariants}>Shailesh Patil</motion.h1>
                <motion.p variants={itemVariants}>
                    I'm
                    <TypedReactDemo strings={["Designer", "Developer", "Freelancer"]} />
                </motion.p>
                <motion.a
                    href="https://www.upwork.com/freelancers/~017f064651f332cc2f"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary hire_now_btn"
                    variants={itemVariants}
                    whileHover={{
                        scale: 1.05,
                        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)"
                    }}
                    whileTap={{ scale: 0.95 }}
                >
                    Hire Now
                </motion.a>
            </motion.div>
        </section>
    );
};

export default HeroSection;