import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FactsSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const fadeInVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    const countBoxVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: (custom) => ({
            opacity: 1,
            scale: 1,
            transition: {
                delay: custom * 0.1,
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }),
        hover: {
            scale: 1.05,
            boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.15)",
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 10
            }
        }
    };

    const iconVariants = {
        initial: { scale: 1 },
        hover: {
            scale: 1.2,
            rotate: 5,
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 10
            }
        }
    };

    const counterVariants = {
        initial: { opacity: 1 },
        hover: {
            color: "#6558FF",
            transition: { duration: 0.3 }
        }
    };

    return (
        <section id="facts" className="facts">
            <div className="container">
                <motion.div
                    className="section-title"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                    variants={fadeInVariants}
                >
                    <h2>Facts</h2>
                    <p>
                        In this section, I will describe some of the clients and projects that I have worked with in the past.
                    </p>
                </motion.div>

                <div className="row no-gutters">
                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <motion.div
                            className="count-box"
                            variants={countBoxVariants}
                            custom={0}
                            initial="hidden"
                            whileInView="visible"
                            whileHover="hover"
                            viewport={{ once: true, amount: 0.3 }}
                            onHoverStart={() => setHoveredIndex(0)}
                            onHoverEnd={() => setHoveredIndex(null)}
                        >
                            <motion.i
                                className="bi bi-emoji-smile"
                                variants={iconVariants}
                                animate={hoveredIndex === 0 ? "hover" : "initial"}
                            ></motion.i>
                            <motion.span
                                className="purecounter"
                                variants={counterVariants}
                                animate={hoveredIndex === 0 ? "hover" : "initial"}
                            >12+</motion.span>
                            <p><strong>Happy Clients</strong></p>
                        </motion.div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <motion.div
                            className="count-box"
                            variants={countBoxVariants}
                            custom={1}
                            initial="hidden"
                            whileInView="visible"
                            whileHover="hover"
                            viewport={{ once: true, amount: 0.3 }}
                            onHoverStart={() => setHoveredIndex(1)}
                            onHoverEnd={() => setHoveredIndex(null)}
                        >
                            <motion.i
                                className="bi bi-journal-richtext"
                                variants={iconVariants}
                                animate={hoveredIndex === 1 ? "hover" : "initial"}
                            ></motion.i>
                            <motion.span
                                className="purecounter"
                                variants={counterVariants}
                                animate={hoveredIndex === 1 ? "hover" : "initial"}
                            >35+</motion.span>
                            <p><strong>Projects</strong></p>
                        </motion.div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <motion.div
                            className="count-box"
                            variants={countBoxVariants}
                            custom={2}
                            initial="hidden"
                            whileInView="visible"
                            whileHover="hover"
                            viewport={{ once: true, amount: 0.3 }}
                            onHoverStart={() => setHoveredIndex(2)}
                            onHoverEnd={() => setHoveredIndex(null)}
                        >
                            <motion.i
                                className="bi bi-headset"
                                variants={iconVariants}
                                animate={hoveredIndex === 2 ? "hover" : "initial"}
                            ></motion.i>
                            <motion.span
                                className="purecounter"
                                variants={counterVariants}
                                animate={hoveredIndex === 2 ? "hover" : "initial"}
                            >24</motion.span>
                            <p><strong>Hours Of Support</strong></p>
                        </motion.div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <motion.div
                            className="count-box"
                            variants={countBoxVariants}
                            custom={3}
                            initial="hidden"
                            whileInView="visible"
                            whileHover="hover"
                            viewport={{ once: true, amount: 0.3 }}
                            onHoverStart={() => setHoveredIndex(3)}
                            onHoverEnd={() => setHoveredIndex(null)}
                        >
                            <motion.i
                                className="bi bi-people"
                                variants={iconVariants}
                                animate={hoveredIndex === 3 ? "hover" : "initial"}
                            ></motion.i>
                            <motion.span
                                className="purecounter"
                                variants={counterVariants}
                                animate={hoveredIndex === 3 ? "hover" : "initial"}
                            >3+</motion.span>
                            <p><strong>Hard Workers</strong></p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FactsSection;